import React from "react";
import PropTypes from "prop-types";
import KbygFooter from "./sections/KbygFooter";
import KbygHeader from "./sections/KbygHeader"
import KbygAccomodationsExtraHeader from "./sections/KbygAccomodationsExtraHeader"
import KbygPersonalAgenda from "./sections/KbygPersonalAgenda"
import { getMetadataValue } from "../utility/registration/RegFormUtility";

class KbygIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSections: []
    }
  }

  isSectionOpen(slug) {
    const { openSections } = this.state;
    const sections = this.getSections();
    if(openSections.indexOf(slug) != -1) {
      return true
    }
    const currentSection = sections.find(section => section.slug === slug)
    if(currentSection.forceOpen) {
      return true
    }
    return false
  }

  renderPersonalAgendaContent() {
    const { attendee } = this.props;
    return (
      <div className="sg-kbyg-body sg-kbyg-section-content-general-section">
        <p>
          All Press and Analyst Conference sessions and Partner Summit keynotes will take place at the&nbsp;<a href="https://www.google.com/url?sa=t&amp;rct=j&amp;q=&amp;esrc=s&amp;source=web&amp;cd=&amp;cad=rja&amp;uact=8&amp;ved=2ahUKEwjp7Ir74tyBAxWtKFkFHe-zDfAQFnoECA8QAQ&amp;url=https%3A%2F%2Fwww.miamibeachconvention.com%2F&amp;usg=AOvVaw1x-q_6c4gkB8uiWykW7K81&amp;opi=89978449" target="_blank" rel="noopener">Miami Beach Convention Center</a> unless otherwise noted.
        </p>
        <p>
          <strong>Event Location</strong> <br />Miami Beach Convention Center<br />1901 Convention Ctr Dr, Miami Beach, FL 33139<br />+17862762600
        </p>
        {
          attendee['registration_answers']['ciscoreservehotel'] === 'Yes' ?
          <p>
            <strong>Hotel Accommodation</strong> <br />Loews Miami Beach<br />1601 Collins Ave, Miami Beach, FL 33139<br />+13056041601<br /><a href="https://www.loewshotels.com/miami-beach" target="_blank" rel="noopener">https://www.loewshotels.com/miami-beach</a>
          </p>
          :
          <></>
        }
      </div>
    );
  }

  renderAccommodations(attendee, cms_section) {
    const content1 = ((cms_section||{}).answers||{}).text1
    const content2 = ((cms_section||{}).answers||{}).text2

    if (getMetadataValue('allow-cisco-to-book-hotel', attendee["metadata"])=='yes-but-i-will-pay') {
      return (
        <div className="sg-kbyg-body sg-kbyg-section-content-general-section" dangerouslySetInnerHTML={{__html: content2}}/>
      )
    }
    return (
      <div className="sg-kbyg-body sg-kbyg-section-content-general-section" dangerouslySetInnerHTML={{__html: content1}}/>
    )
  }

  renderOnsiteContent() {
    const { attendee } = this.props;
    return (
      <div className="sg-kbyg-body sg-kbyg-section-content-general-section">
        <p>Please contact the following should you require any assistance while on site:</p>
        {
          attendee.type === "Analyst" ?
          <p>
            <strong>Analyst Relations</strong>
            <br />
            Charlotte Hilditch, Analyst Relations Manager,
            <br />
            +447808320003, <a href="mailto:childitc@cisco.com">childitc@cisco.com</a>
            <br />
            <br />
            Brenna Karr, Senior Director Corporate & International Analyst Relations,
            <br />
            +19498870630, <a href="mailto:brekarr@cisco.com">brekarr@cisco.com</a>
          </p>
          :
          <></>
        }
        {
          attendee.type === "Press" ?
          <p>
            <strong>Public Relations</strong>
            <br />
            Amy Lucas, Communications Manager,
            <br />
            +447881918160, <a href="mailto:amylucas@cisco.com">amylucas@cisco.com</a>
            <br />
            <br />
            Matthew Kelly, Communications Specialist,
            <br />
            +17165255064, <a href="mailto:matkell2@cisco.com">matkell2@cisco.com</a>
          </p>
          :
          <></>
        }
        <p>We look forward to seeing you in Amsterdam.</p>
      </div>
    );
  }
  getSections() {
    const { cms_section, schedule, attendee, bookings } = this.props;
    const sections = [
      {
        slug: "pac-emea24-kbyg-header",
        raw_render: true,
        forceOpen: true,
        content: <KbygHeader cms_section={ this.getCmsItem("pac-emea24-kbyg-header")} />
      },
      {
        slug: "pac-emea24-kbyg-event-location",
      },
      {
        slug: "pac-emea24-kbyg-personal-agenda",
        content: (
          <KbygPersonalAgenda
            attendee={attendee}
            schedule={schedule}
            cms_section={this.getCmsItem("pac-emea24-kbyg-personal-agenda")}
          />
        ),
        headerButton: (
          <div className="sg-kbyg-personal-agenda-schedule-download-section" style={{marginTop: "20px"}}>
            {
              schedule.error !== undefined ?
              <></>
              :
              <a href={`https://live25-emea.ciscoevents.net/reports/attendees/${schedule.attendee.id}`} target="_blank">
                <button className="sg-round-button" style={{backgroundColor: "#0D274D"}}>
                  Download PDF
                </button>
              </a>
            }
          </div>
        ),
        hidden: schedule.error !== undefined
        // subcontent: <KbygPersonalAgenda
        //   attendee={attendee}
        //   schedule={schedule}
        //   cms_section={this.getCmsItem("clus24-personal-agenda")} />,
        //  disableDivider: true
      },
      // {
      //   slug: "pac-emea24-kbyg-maps",
      // },
      {
        slug: "pac-emea24-kbyg-accomodations",
        headerExtraContent: (
          <KbygAccomodationsExtraHeader
            attendee={attendee}
            booking={bookings}
          />
        ),
        content: (this.renderAccommodations(attendee, this.getCmsItem("pac-emea24-kbyg-accomodations"))),
        hidden: (
          getMetadataValue('cisco-book-and-pay-hotel', attendee["metadata"])=='no'
          ||
          getMetadataValue('cisco-book-and-pay-hotel', attendee["metadata"])==''
          ||
          getMetadataValue('suppress-hotel', attendee["metadata"])=='yes'
        )
      },
      {
        slug: "pac-emea24-kbyg-arrival",
        // hidden: attendee['registration_answers']['requireairtransportation']!='Yes'
        hidden: (
          getMetadataValue('ground-transportation-lax', attendee["metadata"])=='no' //Says LAX because the metadata field was cloned from PS24 and never renamed
          ||
          getMetadataValue('ground-transportation-lax', attendee["metadata"])==''
          ||
          getMetadataValue('suppress-travel', attendee["metadata"])=='yes'
        )
      },
      {
        slug: "pac-emea24-kbyg-departure",
        // hidden: attendee['registration_answers']['requireairtransportation']!='Yes'
        hidden: (
          getMetadataValue('ground-transportation-lax', attendee["metadata"])=='no'
          ||
          getMetadataValue('ground-transportation-lax', attendee["metadata"])==''
          ||
          getMetadataValue('suppress-travel', attendee["metadata"])=='yes'
        )
      },
      {
        slug: "pac-emea24-kbyg-onsite-reg",
      },
      {
        slug: "pac-emea24-kbyg-anniversary",
      },
      {
        slug: "pac-emea24-kbyg-health",
      },
      {
        slug: "pac-emea24-kbyg-attire",
      },
      {
        slug: "pac-emea24-kbyg-weather",
      },
      {
        slug: "pac-emea24-kbyg-onsite-assistance",
        // customHeader: (
        //   <div className="sg-kbyg-section-header-inner">
        //     <div className="sg-kbyg-section-header-title" style={{color: "#00BCEB", marginBottom: "20px"}}>
        //       {"Onsite Assistance"}
        //     </div>
        //   </div>
        // ),
        // content: (
        //   this.renderOnsiteContent()
        // ),
        disableDivider: true,
        // forceOpen: true,
      },
    ].filter(x=>!x.hidden);

    const sections_with_cms_content = sections.map(section =>{
      section.cms_section = this.getCmsItem(section.slug)
      section.mobile_image = `/images/kbyg/${section.slug}-mobile.svg`
      section.desktop_image = `/images/kbyg/${section.slug}.svg`
      return section
    })


    return sections_with_cms_content
  }

  getCmsItem(slug) {
    const { cms_section } = this.props;
    return ((cms_section||{}).items||[]).find(x=>x.slug == slug)
  }

  renderSections() {
    const { attendee, schedule } = this.props;
    return this.getSections().map(section => {
      if(section.raw_render == true) {
        return section.content
      }
      return(
        <div className={`col-xs-12 sg-kbyg-section sg-kbyg-section-${section.slug} ${this.isSectionOpen(section.slug) ? "open" : "closed"}`} key={section.slug}>
          <div className={`sg-kbyg-section-content row`}>
            <div className="sg-kbyg-section-header col-xs-12 col-md-4">
              {this.renderSectionHeader(section)}
              {this.renderSectionHeaderExtras(section)}
              {this.renderHeaderButton(section)}
            </div>
            <div className="sg-kbyg-section-content-main-content col-xs-12 col-md-8">
              {section.content ? section.content : this.renderGeneralContent(section)}
            </div>
          </div>
          <div className={`sg-kbyg-section-subcontent`}>
            {section.subcontent}
          </div>
          {section.disableDivider ? "" : <div className={`sg-kbyg-section-divider`}/>}
        </div>
      )
    })
  }

  toggleSection(slug) {
    const { openSections } = this.state;
    if(this.isSectionOpen(slug)){
      this.setState({
        openSections: openSections.filter(section => section != slug)
      })
    } else{
      this.setState({
        openSections: openSections.concat(slug)
      })
    }

  }

  renderHeaderButton(section) {
    if (section.headerButton) {
      return section.headerButton;
    }
    return <></>;
  }

  renderSectionHeaderExtras(section) {
    if(section.headerExtraContent) {
      return (
        <div className="sg-kbyg-section-header-extras">
          {section.headerExtraContent}
        </div>
      )
    }
  }
  renderSectionHeader(section) {
    if(section.customHeader) {
      return section.customHeader
    }
    return (
      <div className="sg-kbyg-section-header-inner" onClick={()=>this.toggleSection(section.slug)}>
        <div className="sg-kbyg-section-header-inner-image-container">
          {/* <img src={section.mobile_image} className="sg-kbyg-section-header-image hidden-sm hidden-xl hidden-lg hidden-md"/> */}
          <img src={section.desktop_image} className="sg-kbyg-section-header-image sg-kbyg-section-header-image-mobile hidden-sm hidden-xl hidden-lg hidden-md"/>
          <img src={section.desktop_image} className="sg-kbyg-section-header-image hidden-xs "/>
        </div>
        <div className="sg-kbyg-section-header-title">
          {section.cms_section ? section.cms_section.answers.title : ""}
        </div>
        <div className="sg-kbyg-section-header-signfier">
          <img src="/images/kbyg/Chevron_Mobile.svg"/>
        </div>
      </div>
    )
  }

  renderGeneralContent(section) {
    const content = ((section.cms_section||{}).answers||{}).text1
    return (
      <div className="sg-kbyg-body sg-kbyg-section-content-general-section" dangerouslySetInnerHTML={{__html: content}}/>
    )
  }

  render() {
    return (
      <div className="row">
        {this.renderSections()}
      </div>
    )
  }
}

export default KbygIndex;
